<template>
  <div class="pageContol UnitEdit">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">答题红包活动列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a"
            >{{ unitJson.stu != "edit" ? "新增" : "修改" }}答题红包活动</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box activeConfig">
              <el-form
                ref="ruleForm"
                label-width="8rem"
                class="form"
                :rules="rules"
                :model="ruleForm"
                :disabled="unitJson.isPublish"
              >
              <h4>活动信息</h4>
                <el-form-item label="活动名称" prop="activityName">
                  <el-input
                    v-model="ruleForm.activityName"
                    size="small"
                    maxlength="50"
                    show-word-limit
                  ></el-input>
                </el-form-item>

                <el-form-item label="活动起止日期" prop="activityDate">
                  <el-date-picker
                    v-model="ruleForm.activityDate"
                    type="daterange"
                    size="small"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="参与对象" prop="participants">
                  <el-input
                    v-model="ruleForm.participants"
                    size="small"
                    maxlength="30"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="活动区域" prop="areaId">
                  <el-cascader
                    clearable
                    filterable
                    v-model="ruleForm.areaId"
                    :options="areaTreeList"
                    :props="propsArea"
                    size="small"
                  ></el-cascader>
                </el-form-item>

                <el-form-item label="主办方" prop="organiser">
                  <el-input
                    v-model="ruleForm.organiser"
                    size="small"
                    clearable
                    maxlength="30"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="背景图片" prop="backgroundImgUrl">
                  <el-upload
                    :on-change="handleAvatarSuccess2"
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="
                        ruleForm.backgroundImgUrl ||
                        require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                  </el-upload>
                  <p style="color: #f46173">
                    请上传PNG、JPG格式的图片，且文件大小不超过2MB！
                  </p>
                </el-form-item>
                <el-form-item label="活动规则" prop="activityRule">
                  <div
                  ref="editor"
                  class="editor"
                  style="width: 100%; max-width: 1200px"
                />
                </el-form-item>
                <h4>奖项设置</h4>
                <el-form-item label="增加奖项" prop="compUser">
                  <el-button class="bgc-bv" @click="IncreaseAwards" size="small"
                    >新增奖项</el-button
                  >
                  <el-table
                    :data="ruleForm.list"
                    :highlight-current-row="true"
                    size="small"
                    tooltip-effect="dark"
                    style="width: 100%"
                    stripe
                    class="tb-edit"
                    ><el-table-column
                      label="序号"
                      align="center"
                      type="index"
                      :index="indexMethod"
                      width="80px"
                    ></el-table-column>

                    <el-table-column
                      label="奖项名称"
                      align="left"
                      show-overflow-tooltip
                      prop="prizeName"
                      min-width="120"
                      ><template slot-scope="scope">
                        <el-input
                          size="small"
                          v-model="scope.row.prizeName"
                          placeholder="请输入"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="活动图标"
                      align="left"
                      show-overflow-tooltip
                      prop="activityLogo"
                      min-width="120"
                    >
                    </el-table-column>
                    <el-table-column
                      label="中奖概率(%)"
                      align="left"
                      show-overflow-tooltip
                      prop="winProbability"
                      min-width="120"
                      ><template slot-scope="scope">
                        <el-input
                          size="small"
                          v-model="scope.row.winProbability"
                          onkeyup="this.value=this.value.replace(/[^\d\.]/g, '')"
                          placeholder="请输入"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="奖项面值(元)"
                      align="left"
                      show-overflow-tooltip
                      prop="bonus"
                      min-width="120"
                      ><template slot-scope="scope">
                        <el-input
                          size="small"
                          v-model="scope.row.bonus"
                          onkeyup="this.value=this.value.replace(/[^\d\.]/g, '')"
                          placeholder="请输入"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="操作"
                      align="center"
                      width="160px"
                      fixed="right"
                    >
                      <div slot-scope="scope">
                        <el-button
                          type="text"
                          size="mini"
                          style="padding: 0 5px"
                          @click="deletePrize(scope.$index)"
                          >删除</el-button
                        >
                      </div>
                    </el-table-column>
                  </el-table>
                </el-form-item>
                <h4>答题规则</h4>
                <el-form-item label="题库名称" prop="questionBankId">
                  <!-- /question/bank/selectQbBank -->
                  <el-select
                    v-model="ruleForm.questionBankId"
                    placeholder="请选择题库"
                    filterable
                    remote
                    size="small"
                    :remote-method="getQuestionBank"
                  >
                    <el-option
                      :label="item.questionBankName"
                      :value="item.questionBankId"
                      v-for="(item, index) in questionBankList"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <div style="display: flex" class="topics">
                  <el-form-item prop="topicsNum" class="paperObj">
                    随机抽取题目数量
                    <el-input-number
                      v-model="ruleForm.topicsNum"
                      size="small"
                      :precision="0"
                      :min="0"
                      style="width: 140px; margin-right: 10px"
                    ></el-input-number
                    >个，其中每道题
                  </el-form-item>
                  <el-form-item prop="topicsScore" class="topicsScore">
                    <el-input-number
                      v-model="ruleForm.topicsScore"
                      size="small"
                      :min="0"
                      style="width: 140px; margin-right: 10px"
                    ></el-input-number
                    >分，共{{ ruleForm.totalScore }}分。
                  </el-form-item>
                </div>
                <el-form-item prop="exactAmount" class="paperObj">
                  <div>
                    <p>
                      用户答对
                      <el-input-number
                        v-model="ruleForm.exactAmount"
                        size="small"
                        :precision="0"
                        :min="0"
                        style="width: 140px; margin-right: 10px"
                      ></el-input-number
                      >个题目，即可抽奖。
                    </p>
                  </div>
                </el-form-item>
                <el-form-item prop="limitTime" class="paperObj">
                  <div>
                    <p>
                      每道题限时
                      <el-input
                        size="small"
                        placeholder="请输入限时时间"
                        v-model="ruleForm.limitTime"
                        style="width: 200px"
                      >
                        <template slot="append">秒</template>
                      </el-input>
                    </p>
                  </div>
                </el-form-item>
                <div style="display: flex; align-items: center" class="topics">
                  <el-form-item prop="totalAmount" class="paperObj">
                    奖池金总额
                    <el-input
                      size="small"
                      placeholder="请输入奖池金总额"
                      v-model="ruleForm.totalAmount"
                      style="width: 200px"
                    >
                      <template slot="append">元</template> </el-input
                    >,每日划转
                  </el-form-item>
                  <el-form-item
                    prop="dailyDistributionAmount"
                    class="topicsScore"
                  >
                  <div>
                    <p>
                      每日发放金额
                     <el-input
                      size="small"
                      placeholder="请输入每日发放金额"
                      style="width: 200px"
                      v-model="ruleForm.dailyDistributionAmount"
                    >
                      <template slot="append">元</template>
                    </el-input>
                    </p>
                  </div>
                  </el-form-item>
                </div>
              <h4>抽奖规则</h4>
                <el-form-item prop="isDailyParticipation" class="paperObj">
                  <div>
                    <p style="display: flex">
                      <el-select
                        v-model="ruleForm.isDailyParticipation"
                        placeholder="请选择"
                        style="width: 200px"
                        size="small"
                      >
                        <el-option label="允许" :value="true"></el-option>
                        <el-option
                          label="不允许"
                          :value="false"
                        ></el-option> </el-select
                      >用户每天参加抽奖。
                    </p>
                  </div>
                </el-form-item>
                <el-form-item
                  prop="dailyLuckyDrawNum"
                  class="paperObj"
                  v-if="ruleForm.isDailyParticipation"
                >
                  每人每天允许抽奖
                  <el-input
                    size="small"
                    placeholder="请输入"
                    v-model="ruleForm.dailyLuckyDrawNum"
                    style="width: 200px"
                  >
                    <template slot="append">次</template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="onlyLuckyDrawNum" class="paperObj" v-else>
                  每人只允许抽奖
                  <el-input
                    size="small"
                    placeholder="请输入"
                    v-model="ruleForm.onlyLuckyDrawNum"
                    style="width: 200px"
                  >
                    <template slot="append">次</template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="isLimitAnswer" class="paperObj">
                  <div>
                    <p style="display: flex">
                      <el-select
                        v-model="ruleForm.isLimitAnswer"
                        placeholder="请选择"
                        size="small"
                        style="width: 140px; margin-right: 10px"
                      >
                        <el-option label="限制" :value="true"></el-option>
                        <el-option
                          label="不限制"
                          :value="false"
                        ></el-option> </el-select
                      >用户的答题次数。
                    </p>
                  </div>
                </el-form-item>
                <el-form-item
                  prop="answerNum"
                  class="paperObj"
                  v-if="ruleForm.isLimitAnswer"
                >
                  <div>
                    <p style="display: flex">
                      每个用户最多可以有
                      <el-input
                        size="small"
                        placeholder="请输入"
                        v-model="ruleForm.answerNum"
                        style="width: 200px;margin: 0 10px"
                      >
                      </el-input>次答题机会
                    </p>
                  </div>
                </el-form-item>
                 <div style="display: flex; align-items: center" class="topics">
                  <el-form-item prop="shareNum" class="paperObj">
                    用户分享给朋友
                   <el-input-number
                        v-model="ruleForm.shareNum"
                        size="small"
                        :precision="0"
                        :min="0"
                        style="width: 140px; margin-right: 10px"
                      ></el-input-number
                      >次，增加
                  </el-form-item>
                  <el-form-item
                    prop="additionalNum"
                    class="topicsScore"
                  >
                   <el-input-number
                        v-model="ruleForm.additionalNum"
                        size="small"
                        :min="0"
                        style="width: 140px; margin-right: 10px"
                      ></el-input-number
                      >次抽奖机会。
                  </el-form-item>
                </div>
                <el-form-item prop="isLimitArea" class="paperObj">
                  <div>
                    <p style="display: flex">
                      <el-select
                        v-model="ruleForm.isLimitArea"
                        placeholder="请选择"
                        size="small"
                        style="width: 140px; margin-right: 10px"
                      >
                        <el-option label="限定" :value="true"></el-option>
                        <el-option
                          label="不限定"
                          :value="false"
                        ></el-option> </el-select
                      >用户定位在活动区域所在市的范围内。
                    </p>
                  </div>
                </el-form-item>
              </el-form>
            </div>

            <div class="btn-box flexcc">
              <el-button @click="doCancel" class="bgc-bv">取 消</el-button>
              <el-button
                class="bgc-bv"
                @click="doAddSave('ruleForm')"
                v-if="!unitJson.isPublish"
                >确 定</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import E from "wangeditor";
let editor = {};
import UploadPic from "@/mixins/UploadPic";
import { mapGetters } from "vuex";
export default {
  name: "UnitEdit",
  components: {},
  mixins: [UploadPic],
  data() {
    return {
      stu: "add",
      // 基本信息
      ruleForm: {
        activityName: "", //活动名称
        activityDate: "", //活动日期
        participants: "", //参与对象
        areaId: "", //活动区域
        organiser: "", //主办方
        backgroundImgUrl: "", //背景图片url
        backgroundImg: "", //背景图片key
        activityRule: "", //活动规则
        paperId: "", //题库Id
        topicsNum: "5", //随机抽取题目数量
        topicsScore: "20", //随机抽取题目分值
        exactAmount: "4", //答对数量
        limitTime: "60", //限时时间
        totalAmount: "", //总金额
        dailyDistributionAmount: "", //每日划转金额
        isDailyParticipation: true, //是否每日参与
        answerNum: "",
        dailyLuckyDrawNum: "1", //每人每天允许次数
        onlyLuckyDrawNum: "1", //每人只允许次数
        isLimitAnswer: false, //是否限制答题次数
        shareNum: "2", //分享次数
        additionalNum: "1", //增加次数
        isLimitArea: false, //限定区域
        totalScore: "", //总分
        list: [],
      },
      rules: {
        activityName: [
          { required: true, trigger: "blur", message: "请输入活动名称" },
        ],
        activityDate: [
          { required: true, trigger: "change", message: "请选择活动日期" },
        ],
        participants: [
          { required: true, trigger: "blur", message: "请输入参与对象" },
        ],
        areaId: [
          { required: true, trigger: "change", message: "请选择活动区域" },
        ],
        organiser: [
          { required: true, trigger: "blur", message: "请输入主办方" },
        ],
        backgroundImgUrl: [
          { required: true, trigger: "change", message: "请上传图片" },
        ],
        activityRule: [
          { required: true, trigger: "blur", message: "请输入活动规则" },
        ],
        questionBankId: [
          { required: true, trigger: "change", message: "请选择题库" },
        ],
        topicsNum: [
          { required: true, trigger: "blur", message: "请输入随机抽取的数量" },
        ],
        topicsScore: [
          { required: true, trigger: "blur", message: "请输入分数" },
        ],
        exactAmount: [
          {
            required: true,
            trigger: "blur",
            message: "请输入用户答对题目数量",
          },
        ],
        limitTime: [
          { required: true, trigger: "blur", message: "请输入每题限时时间" },
        ],
        totalAmount: [
          { required: true, trigger: "blur", message: "请输入奖池总金额" },
        ],
        dailyDistributionAmount: [
          { required: true, trigger: "blur", message: "请输入每日划转金额" },
        ],
        isDailyParticipation: [
          {
            required: true,
            trigger: "change",
            message: "请选择是否允许用户每天参加抽奖",
          },
        ],
        dailyLuckyDrawNum: [
          {
            required: true,
            trigger: "change",
            message: "请输入每人每天允许抽奖次数",
          },
        ],
        onlyLuckyDrawNum: [
          {
            required: true,
            trigger: "change",
            message: "请输入每人只允许抽奖次数",
          },
        ],
        isLimitAnswer: [
          {
            required: true,
            trigger: "change",
            message: "请选择是否限制用户答题次数",
          },
        ],
        answerNum: [
          { required: true, trigger: "blur", message: "请输入答题次数" },
        ],
        shareNum: [
          { required: true, trigger: "blur", message: "请输入分享次数" },
        ],
        additionalNum: [
          { required: true, trigger: "blur", message: "请输入增加抽奖次数" },
        ],
        isLimitArea: [
          {
            required: true,
            trigger: "change",
            message: "请选择是否限制用户定位活动区域",
          },
        ],
      },
      tableObj: {
        prizeName: "", //奖项名称
        activityLogo: "", //logo
        winProbability: "", //中奖概率
        bonus: "", //奖金
      },
      unitJson: {},
      areaTreeList: [],
      propsArea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      questionBankList: [],
    };
  },
  created() {
    this.unitJson = JSON.parse(this.$route.query.getClassInfo);
    console.log(this.unitJson.isPublish);
    this.getAreaTree();
    if (this.unitJson.stu == "edit") {
      this.queryData(this.unitJson.activityId);
    }
  },
   mounted() {
    this.editorInit();
  },
  computed: {},
  methods: {
      // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;

      editor.config.onchange = (html) => {
        this.ruleForm.activityRule = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.backgroundImgUrl = result.data.fileURL;
            this.ruleForm.backgroundImg = result.data.fileKey;
            // console.log(result);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 编辑保存
    doAddSave(formName) {
      if(this.ruleForm.list.length == 0) {
        this.$message.error('奖项列不能为空')
        return false
      }
      for (let i = 0; i < this.ruleForm.list.length; i++) {
        if(this.ruleForm.list[i].prizeName == '' && this.ruleForm.list[i].winProbability == '' &&this.ruleForm.list[i].bonus == '') {
          this.$message.error('奖项名称,中奖概率,奖项面值不能为空')
          return false
        }
        
      }
      const params = {
        activityName: this.ruleForm.activityName, //活动名称
        participants: this.ruleForm.participants, //参与对象
        areaId: this.ruleForm.areaId, //活动区域
        organiser: this.ruleForm.organiser, //主办方
        activityRule: this.ruleForm.activityRule, //活动规则
        isLimitAnswer: this.ruleForm.isLimitAnswer, //是否限制答题次数
        isLimitArea: this.ruleForm.isLimitArea, //限定区域
        backgroundImg: this.ruleForm.backgroundImg,
      };
      //活动日期
      if (this.ruleForm.activityDate) {
        params.startDate =this.ruleForm.activityDate[0].length <= 10
                ? this.ruleForm.activityDate[0] + " 00:00:00"
                : this.ruleForm.activityDate[0];
        params.endDate = this.ruleForm.activityDate[1].length <= 10
                ? this.ruleForm.activityDate[1] + " 00:00:00"
                : this.ruleForm.activityDate[1];
      }
      //题库id
      if (this.ruleForm.questionBankId) {
        params.questionBankId = this.ruleForm.questionBankId;
      }
      //随机抽取题目数量
      if (this.ruleForm.topicsNum) {
        params.topicsNum = this.ruleForm.topicsNum;
      }
      //随机抽取题目分值
      if (this.ruleForm.topicsScore) {
        params.topicsScore = this.ruleForm.topicsScore;
      }
      //答对数量
      if (this.ruleForm.exactAmount) {
        params.exactAmount = this.ruleForm.exactAmount;
      }
      //限时时间
      if (this.ruleForm.limitTime) {
        params.limitTime = this.ruleForm.limitTime;
      }
      //奖池总金额
      if (this.ruleForm.totalAmount) {
        params.totalAmount = this.ruleForm.totalAmount;
      }
      //每日划转金额
      if (this.ruleForm.dailyDistributionAmount) {
        params.dailyDistributionAmount = this.ruleForm.dailyDistributionAmount;
      }
      if (this.ruleForm.isDailyParticipation) {
        params.isDailyParticipation = this.ruleForm.isDailyParticipation;
        params.dailyLuckyDrawNum = this.ruleForm.dailyLuckyDrawNum;
      } else {
        params.isDailyParticipation = this.ruleForm.isDailyParticipation;
        params.onlyLuckyDrawNum = this.ruleForm.onlyLuckyDrawNum;
      }
      if (this.ruleForm.shareNum) {
        params.shareNum = this.ruleForm.shareNum;
      }
      if (this.ruleForm.additionalNum) {
        params.additionalNum = this.ruleForm.additionalNum;
      }
      if (this.ruleForm.isLimitAnswer) {
        params.answerNum = this.ruleForm.answerNum;
      }
      if (this.ruleForm.list) {
        params.list = this.ruleForm.list;
      }
      if (this.unitJson.stu == "edit") {
        if (this.unitJson.activityId) {
          params.activityId = this.unitJson.activityId;
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(
            this.unitJson.stu == "add"
              ? "/biz/activity/insert"
              : "/biz/activity/modify",
            params
          )
            .then((res) => {
              ;
              if (res.status == 0) {
                this.$message({
                  message: this.unitJson.stu == "add" ? "新增成功" : "修改成功",
                  type: "success",
                });
                // setTimeout(() => {
                this.doCancel();
                // }, 3000);
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    //行政区划
    getAreaTree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areaTreeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 编辑查询
    queryData(activityId) {
      this.$post("/biz/activity/getInfo", { activityId })
        .then((res) => {
          const data = res.data || {};
          this.ruleForm = {
            ...data,
            activityDate: [
              data.startDate.replaceAll("/", "-"),
              data.endDate.replaceAll("/", "-"),
            ],
          };
          editor.txt.html(data.activityRule);
          this.getQuestion(data.questionBankId);
        })
        .catch((err) => {
          return;
        });
    },
    //题库模糊搜索
    getQuestionBank(query) {
      if (query) {
        this.$post(
          "/question/bank/selectQbBank",
          {
            questionBankName: query,
          },
          3000,
          true,
          2
        ).then((res) => {
          this.questionBankList = res.data;
        });
      }
    },
    //回显题库
    getQuestion(questionBankId) {
      this.$post(
        "/question/bank/qbBankEcho",
        { questionBankId },
        3000,
        true,
        2
      ).then((res) => {
        this.questionBankList = [res.data];
      });
    },
    /* 取消 */
    doCancel() {
      this.$router.push({
        path: "/web/workOrder/activeConfigurationList",
        query: {
          refresh: true,
        },
      });
    },
    //新增奖项
    IncreaseAwards() {
      this.ruleForm.list.push({ ...this.tableObj });
    },
    //删除奖项
    deletePrize(index) {
      this.ruleForm.list.splice(index, 1);
    },
  },
  watch: {
    // 计算总题数 || 总分
    ruleForm: {
      handler: function (val) {
        let { topicsNum, topicsScore } = this.ruleForm;
        this.ruleForm.totalScore = (
          Number(topicsNum) * Number(topicsScore)
        ).toFixed(1);
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.topics {
  .topicsScore {
    .el-form-item__content {
      margin-left: 0.5rem !important;
    }
  }
}
.activeConfig {
  h4 {
    font-size: 18px;
    padding:1rem 2rem;
    &:before {
      content:'';
       border-left: 3px solid #2878ff;
           margin-right: 10px;
    }
  }
}
</style>

